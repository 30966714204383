import { adminImpersonateSchema } from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const impersonation = c.router(
   {
      impersonate: {
         method: 'POST',
         path: '/impersonate',
         body: adminImpersonateSchema,
         responses: {
            200: c.noBody(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
