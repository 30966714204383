import { createInnerwellApiClient } from '@innerwell/api-client';
import { getCookieSession } from '@innerwell/utils';
import {
   authenticate,
   clinicians,
   cognito,
   encounters,
   formsort,
   impersonation,
   insurance,
   patients,
   payments,
   precision,
   precisionPrescription,
   products,
   scheduling,
   serviceProviderLocations,
   services,
   states,
   treatmentMethods,
   users,
   welkin,
} from '@innerwell/admin-contracts';
import { isAxiosError } from 'axios';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

/**
 * New api client
 */
const routerContracts = {
   authenticate,
   clinicians,
   cognito,
   encounters,
   formsort,
   impersonation,
   insurance,
   patients,
   payments,
   precision,
   precisionPrescription,
   products,
   scheduling,
   serviceProviderLocations,
   services,
   states,
   treatmentMethods,
   welkin,
   users,
};

const REFRESH_PATH = '/';

export const adminApiClient = createInnerwellApiClient(
   {
      baseURL: getClientPublicRuntimeConfig().apiUrl,
      useRefreshTokenInterceptors: true,
      onRefreshTokenError(error) {
         if (
            isAxiosError(error) &&
            error.response &&
            window.location.pathname !== REFRESH_PATH &&
            [401, 403].includes(error.response.status)
         ) {
            window.location.href = `${REFRESH_PATH}?${new URLSearchParams({
               error: 'RefreshTokenError',
               callbackUrl: window.location.href,
            })}`;
         }
      },
      withCredentials: true,
      getCookieExp: () => {
         return getCookieSession<{ exp: number }>('admin_')?.exp;
      },
   },
   routerContracts,
);
