import {
   createAdminEncounterSchema,
   dateField,
   intExtendedField,
   type RuleBasedBoundsResponseDto,
   uuidSchema,
   type WelkinEncounterTemplateNames,
   type EncounterCountSourceType,
   type CreateAdminEncounterResponseDto,
   getClinicianServicesParamsSchema,
   type AdminGetClinicianLocationsResultDto,
   type AdminGetClinicianServicesResultDto,
   getClinicianLocationsParamsSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const scheduling = c.router(
   {
      // Returns all clinician locations for logged in clinician (if role is clinician) or requested clinician (role = admin)
      getClinicianLocations: {
         method: 'GET',
         path: '/scheduling/clinician-locations',
         query: getClinicianLocationsParamsSchema,
         responses: {
            200: c.type<AdminGetClinicianLocationsResultDto[]>(),
         },
      },

      // Returns all clinician services for logged in clinician (if role is clinician)  or requested clinician (role = admin)
      getClinicianServices: {
         method: 'GET',
         path: '/scheduling/clinician-services',
         query: getClinicianServicesParamsSchema,
         responses: {
            200: c.type<AdminGetClinicianServicesResultDto[]>(),
         },
      },

      getRuleBasedBounds: {
         method: 'GET',
         path: '/scheduling/rule-based-bounds',
         query: z.object({
            patientId: z.string().uuid(),
            serviceId: intExtendedField,
         }),
         responses: {
            200: c.type<RuleBasedBoundsResponseDto>(),
         },
      },

      getClinicianMonthlyAvailability: {
         method: 'GET',
         path: '/scheduling/clinician-monthly-availability',
         query: z.object({
            clinicianId: uuidSchema.nullish(),
            locationId: intExtendedField,
            serviceId: intExtendedField,
            year: intExtendedField,
            month: intExtendedField.refine(
               (val) => val >= 1 && val <= 12,
               'Month must be between 1 and 12',
            ),
         }),
         responses: {
            // returns array of available dates
            200: c.type<string[]>(),
         },
      },

      getClinicianAvailabilityForDate: {
         method: 'GET',
         path: '/scheduling/clinician-day-availability',
         query: z.object({
            clinicianId: uuidSchema.nullish(),
            date: dateField,
            locationId: intExtendedField,
            serviceId: intExtendedField,
         }),
         responses: {
            200: c.type<
               {
                  // date time ISO string in UTC
                  start: string;
                  end: string;
               }[]
            >(),
         },
      },

      getEncounterBankForEncounter: {
         method: 'GET',
         path: '/scheduling/encounter-bank',
         query: z.object({
            // Don't force WelkinEncounterTemplateNames; we don't have them all in the
            // code. Allow string, the code will fail elsewhere with maybe meaningful error.
            // Example: instead of validation error, we are now getting Warning: Rules config not found for etmp-talk-therapy-insurance
            // which makes more sense for the clinicians.
            encounterTemplate: z
               .string()
               .transform((val) => val as WelkinEncounterTemplateNames),
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<{
               hasValidInsurance: boolean;
               availableToSchedule: number | 'infinite';
               encounterCountType: EncounterCountSourceType;
               // null in purchased means n/a - insurance patients only
               purchasedCount: number | null;
            }>(),
         },
      },

      scheduleEncounter: {
         method: 'POST',
         path: '/scheduling/encounter',
         body: createAdminEncounterSchema,
         responses: {
            200: c.type<CreateAdminEncounterResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
