import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
   createUserSchema,
   listUsersAdminSchema,
   setPasswordAdminSchema,
   setRolesAdminSchema,
   type UserListAdminResultWithRolesDto,
   type UserListAdminResultDto,
   updateUserSchema,
} from '@innerwell/dtos';

const c = initContract();

export const users = c.router(
   {
      getUsers: {
         method: 'GET',
         path: '/users',
         query: listUsersAdminSchema.nullish(),
         responses: {
            200: c.type<{
               pageToken?: string;
               users: UserListAdminResultDto[];
            }>(),
         },
      },
      createUser: {
         method: 'POST',
         path: '/users',
         body: createUserSchema,
         responses: {
            200: c.type<UserListAdminResultDto>(),
         },
      },
      getUser: {
         method: 'GET',
         path: '/users/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<UserListAdminResultWithRolesDto>(),
         },
      },
      updateUser: {
         method: 'PATCH',
         path: '/users/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: updateUserSchema,
         responses: {
            200: c.type<true>(),
         },
      },
      enableUser: {
         method: 'POST',
         path: '/users/:id/enable',
         pathParams: z.object({
            id: z.string(),
         }),
         body: c.noBody(),
         responses: {
            200: c.type<true>(),
         },
      },
      disableUser: {
         method: 'POST',
         path: '/users/:id/disable',
         pathParams: z.object({
            id: z.string(),
         }),
         body: c.noBody(),
         responses: {
            200: c.type<true>(),
         },
      },
      setPassword: {
         method: 'POST',
         path: '/users/:id/set-password',
         pathParams: z.object({
            id: z.string(),
         }),
         body: setPasswordAdminSchema,
         responses: {
            200: z.literal(true),
         },
      },
      setRoles: {
         method: 'PUT',
         path: '/users/:id/roles',
         pathParams: z.object({
            id: z.string(),
         }),
         body: setRolesAdminSchema,
         responses: {
            200: z.literal(true),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
