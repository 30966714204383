import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
   uuidSchema,
   createFillSchema,
   createPrescriptionFromExistingSchema,
   createPrescriptionSchema,
   getPrescriptionsParamsSchema,
   type FillResponseDto,
   type PrescriptionResponseDto,
   type PrescriptionResponseItem,
   updateFillSchema,
   GetFillDto,
   type ApiResponse,
} from '@innerwell/dtos';
import {
   type PrecisionMedication,
   type PrecisionWebhookEvent,
} from '@innerwell/prisma-app-generated';

const c = initContract();
export const precisionPrescription = c.router(
   {
      getPrecisionPrescriptions: {
         method: 'GET',
         path: '/precision-prescription',
         query: getPrescriptionsParamsSchema,
         responses: {
            200: c.type<PrescriptionResponseDto>(),
         },
      },

      getPrecisionPrescriptionsCsv: {
         method: 'GET',
         path: '/precision-prescription/csv',
         query: getPrescriptionsParamsSchema,
         responses: {
            200: c.type<string>(),
         },
      },

      getPrescription: {
         method: 'GET',
         path: '/precision-prescription/prescription/:id',
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<PrescriptionResponseItem>(),
         },
      },

      createPrescriptionFromExisting: {
         method: 'POST',
         path: '/precision-prescription/prescription-from-existing',
         body: createPrescriptionFromExistingSchema,
         responses: {
            200: z.string().optional(),
         },
      },

      createPrescription: {
         method: 'POST',
         path: '/precision-prescription/prescription',
         body: createPrescriptionSchema,
         responses: {
            200: z.string().optional(),
         },
      },

      getPrescriptionEvents: {
         method: 'GET',
         path: '/precision-prescription/prescription/:id/events',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<PrecisionWebhookEvent[]>(),
         },
      },

      cancelPrescription: {
         method: 'POST',
         path: '/precision-prescription/prescription/:id/cancel',
         pathParams: z.object({
            id: uuidSchema,
         }),
         body: c.noBody(),
         responses: {
            200: z.void(),
         },
      },

      getFill: {
         method: 'GET',
         path: '/precision-prescription/fill/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<FillResponseDto>(),
         },
      },

      createFill: {
         method: 'POST',
         path: '/precision-prescription/fill',
         body: createFillSchema,
         responses: {
            200: c.type<ApiResponse>(),
         },
      },

      updateFill: {
         method: 'PUT',
         path: '/precision-prescription/fill/:id',
         pathParams: z.object({
            // refill reference id
            id: z.string(),
         }),
         body: updateFillSchema,
         responses: {
            200: c.noBody(),
         },
      },

      getFillFromPrecision: {
         method: 'GET',
         path: '/precision-prescription/precision-fill/:id',
         pathParams: z.object({
            // fill reference
            id: z.string(),
         }),
         responses: {
            200: c.type<GetFillDto>(),
         },
      },

      cancelFill: {
         method: 'POST',
         path: '/precision-prescription/fill/:id/cancel',
         pathParams: z.object({
            id: z.string(),
         }),
         body: c.noBody(),
         responses: {
            200: z.void(),
         },
      },

      getMedications: {
         method: 'GET',
         path: '/precision-prescription/medications',
         responses: {
            200: c.type<PrecisionMedication[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
