import axios, {
   type AxiosInstance,
   type AxiosRequestConfig,
   isAxiosError,
   type Method,
} from 'axios';
import _ from 'lodash';
import { type AppRouter, type ApiFetcherArgs, initClient } from '@ts-rest/core';

// Required overrides for Axios config, used in interceptors
declare module 'axios' {
   export interface AxiosRequestConfig {
      // Prevent checking if cookie is already expired in request interceptor
      // Prevent triggering refresh token endpoint after 401 in response interceptor
      dontCheckCredentials?: boolean;
   }

   // export interface InternalAxiosRequestConfig {
   //    // Used in response interceptor to denote that we already tried to refresh the token
   //    _retry?: boolean;
   // }
}

let refreshPromise: Promise<unknown> | null = null;

// Cookie expiration timestamp in seconds
export type GetCookieExpFn = () => number | null | undefined;

export type OnRefreshTokenErrorFn = (error: unknown) => void;

export type ApiClientParams = AxiosRequestConfig & {
   useRefreshTokenInterceptors?: boolean;
   // Get auth cookie which expiration date will be checked.
   getCookieExp: GetCookieExpFn;
   createAxiosInstance?: (params: ApiFetcherArgs) => AxiosInstance;
   onRefreshTokenError?: OnRefreshTokenErrorFn;
};

/**
 * New api client
 */
export function createInnerwellApiClient<T extends AppRouter>(
   config: ApiClientParams,
   routerContracts: T,
) {
   const {
      useRefreshTokenInterceptors = true,
      getCookieExp,
      createAxiosInstance,
      onRefreshTokenError,
      ...axiosConfig
   } = config || {};

   return initClient(routerContracts, {
      baseUrl: '',
      baseHeaders: {},
      credentials: 'include',
      api: async ({
         path,
         method,
         headers,
         body,
         route,
         fetchOptions,
         ...rest
      }) => {
         const baseUrl = ''; //baseUrl is not available as a param, yet

         const axiosInstance =
            createAxiosInstance?.({
               ...rest,
               path,
               method,
               headers,
               body,
               route,
               fetchOptions,
            }) ??
            axios.create({
               ...axiosConfig,
               validateStatus(status) {
                  // If some status outside of 2xx is defined in responses of a route, then we allow it
                  // without throwing an exception in axios.
                  // Example use case: 409 conflict on account creation that we're processing on frontend
                  // as legit response.
                  return (
                     (status >= 200 && status < 300) ||
                     Object.keys(route.responses)
                        .map((n) => parseInt(n))
                        .includes(status)
                  );
               },
            });

         const dontCheckCredentials =
            // Case 1: can be set dynamically for each request
            headers.dontcheckcredentials?.toString() === 'true' ||
            // Case 2: set statically for the route
            (_.isObject(route.metadata) &&
               'dontCheckCredentials' in route.metadata &&
               route.metadata.dontCheckCredentials === true);
         delete headers.dontcheckcredentials;

         if (useRefreshTokenInterceptors) {
            const refreshToken = async () => {
               return axiosInstance
                  .post<unknown>(`/auth/refresh`, null, {
                     dontCheckCredentials: true,
                  })
                  .catch((error) => {
                     onRefreshTokenError?.(error);
                     return false;
                  });
            };

            // Proactively check for cookie expiration before request is sent.
            // If cookie is expired, try to refresh token and then send request.
            axiosInstance.interceptors.request.use(
               async (interceptedConfig) => {
                  if (interceptedConfig.dontCheckCredentials) {
                     // Refresh request should be passed through, it mustn't wait on
                     // refreshPromise
                     return interceptedConfig;
                  }

                  if (refreshPromise) {
                     await refreshPromise;
                  }
                  const cookieExp = getCookieExp();
                  // preventively check for cookie expiration and use refresh token endpoint
                  if (
                     _.isNumber(cookieExp) &&
                     (cookieExp - 30) * 1000 <= Date.now()
                  ) {
                     if (!refreshPromise) {
                        refreshPromise = refreshToken();
                     }
                     const res = await refreshPromise;
                     refreshPromise = null;

                     if (res === false) {
                        const controller = new AbortController();
                        controller.abort();
                        interceptedConfig.signal = controller.signal;
                     }
                  }

                  return interceptedConfig;
               },
            );

            // @NOTE: If server has sent correct JWT back to us with correct expiration date (it did), we then don't
            // need to check response for 401. However, clock might not be accurate on the client PCs so we might
            // want to leave that check here or for any other unforeseeable situation.
            axiosInstance.interceptors.response.use(
               (res) => res,
               async (error: unknown) => {
                  if (isAxiosError(error)) {
                     const originalRequest = error.config;
                     // Not sure if empty config here is possible. Maybe only in case where request interceptor fails?
                     if (!originalRequest) {
                        return Promise.reject(error);
                     }

                     if (originalRequest.dontCheckCredentials) {
                        return Promise.reject(error);
                     }

                     if (error.response?.status === 401) {
                        // If we don't have a refresh promise, create a new one. We're trying to
                        // minimize requests to the refresh endpoint
                        if (!refreshPromise) {
                           refreshPromise = refreshToken();
                        }

                        const res = await refreshPromise;
                        refreshPromise = null;

                        if (res === false) {
                           // Do nothing, refreshing the token has failed.
                           // Do we expect here request interceptor to catch this and redirect to login?
                           return;
                        }

                        return axios(originalRequest);
                     }

                     return Promise.reject(error);
                  }

                  // Just reject with the error we received
                  return Promise.reject(
                     error instanceof Error
                        ? error
                        : new Error('Unknown error'),
                  );
               },
            );
         }

         const signal = fetchOptions?.signal;
         const result = await axiosInstance.request<unknown>({
            ...fetchOptions,
            signal: signal ?? undefined,
            withCredentials: true,
            method: method as Method,
            url: `${baseUrl}${path}`,
            headers,
            data: body,
            dontCheckCredentials,
         });

         return {
            status: result.status,
            body: result.data,
            headers: result.headers as unknown as Headers,
         };
      },

      throwOnUnknownStatus: true,
   });
}
